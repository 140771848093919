import { Page } from 'components/FbUI/Page'
import {
  PageHeader,
  PageTitle,
  PageTitleRow,
} from 'components/FbUI/Page/styles'
import { Tabs } from 'components/FbUI/Tabs'
import LoadingScreen from 'components/Layout/LoadingScreen'
import { CreateContactRequesJobFromCampaign } from 'components/Modals/CreateContactRequestJobFromCampaignModal'
import { ChainsTable } from 'components/Tables/ChainsTable/ChainsTable'
import { CompaniesTable } from 'components/Tables/CompaniesTable/CompaniesTable'
import { ContactRequestsTable } from 'components/Tables/ContactRequestsTable/ContactRequestsTable'
import { ContactsTable } from 'components/Tables/ContactsTable/ContactsTable'
import { DealsTable } from 'components/Tables/DealsTable/DealsTable'
import { K12Table } from 'components/Tables/K12Table/K12Table'
import { UniversitiesTable } from 'components/Tables/UniversitiesTable/UniversitiesTable'
import {
  CHAINS_TABLE_KEY,
  COLLEGE_AND_UNIVERSITY_TABLE_KEY,
  CONTACT_REQUEST_JOB_TABLE_KEY,
  CONTACTS_TABLE_KEY,
  DEAL_TABLE_KEY,
  getCampaignTableKey,
  getCompanyTableKey,
  K12_TABLE_KEY,
} from 'constants/tableQueryKeys'
import { Campaign } from 'models/campaign'
import { Category, CompanyTypeSlug, SubCategory } from 'models/companies'
import { FilterIdentifier } from 'models/saved_view'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import apiService from 'services/api'
import styled from 'styled-components/macro'
import { featureFlagService } from 'utils/featureFlagService'
import { useNavbarStore } from '../../../components/Layout/navbar-store' // Adjust the path as needed

export function SingleCampaignPage() {
  const featureFlag = featureFlagService()
  const navigate = useNavigate()
  const params = useParams<{ campaign_id: string; tab?: string }>()
  const campaignId = params.campaign_id
  const selectedTab = params.tab ?? 'opportunities'
  const store = useNavbarStore()
  const api = apiService()
  const { data: campaigns } = api.useGetCampaigns()

  const campaign = campaigns?.find(
    (it) => it.id === parseInt(campaignId ?? '-1')
  )

  const hasContactRequests = useMemo(
    () =>
      ['restaurants-bars', 'education-cu', 'education-k12'].includes(
        campaign?.type.slug ?? '-1'
      ),
    [campaign]
  )

  if (!campaign) {
    return <LoadingScreen />
  }

  if (!featureFlag.enableCampaigns) {
    navigate('/')
    return null
  }

  return (
    <Page>
      <PageHeader>
        <PageTitleRow>
          <PageTitle>Campaign: {campaign.name}</PageTitle>
        </PageTitleRow>
        {hasContactRequests && (
          <CreateContactRequesJobFromCampaign campaignId={campaign.id} />
        )}
      </PageHeader>
      <GetCampaignTable
        campaign={campaign}
        selectedTab={selectedTab}
        onTabChange={(tab) => {
          const url = `/campaigns/${campaignId}/${tab}`
          navigate(url)
          if (campaignId) {
            store.setLastCampaignTabVisited(campaignId, url)
          }
        }}
      />
    </Page>
  )
}

function GetCampaignTable(props: {
  campaign: Campaign
  selectedTab: string
  onTabChange: (tab: string) => void
}) {
  const api = apiService()
  const { data: categories, isFetching: categoriesLoading } =
    api.useGetCompanyCategoriesAndSub()

  const campaignTypes = useMemo(() => {
    const types: {
      slug: string
      sub_category: SubCategory
      category: Category
    }[] = []
    categories?.forEach((it) => {
      it.sub_categories.forEach((s) => {
        types.push({
          slug: s.slug,
          sub_category: s,
          category: it,
        })
      })
    })
    return types
  }, [categories])

  const type = campaignTypes.find((it) => it.slug === props.campaign.type.slug)

  if (!type) {
    return <>no type found</>
  }

  if (
    categoriesLoading
    // &&
    // !['restaurants-bars', 'education-cu', 'education-k12'].includes(
    //   campaign.type.slug
    // )
  ) {
    return <>cat loadign</>
  }

  switch (props.campaign.type.slug) {
    case 'restaurants-bars':
      return (
        <TableContainer
          campaign={props.campaign}
          selectedTab={props.selectedTab}
          onTabChange={props.onTabChange}
          key={`campaign-${props.campaign.id}`}
          oppsTable={
            <ChainsTable
              filterIdentifierModifier={
                `_CAMPAIGN_${props.campaign.id}` as FilterIdentifier
              }
              defaultColumnVisibility={{
                chain: true,
                count: true,
                menu: false,
                menu_length: false,
                menu_matches: false,
                total_ltv: false,
                brand_ltv: false,
                revenue_ltv: false,
                one_year_total_value: false,
                taro: false,
                tabo: false,
                pounds_per_year: false,
                note_count: true,
                contact_count: true,
                deal_count: true,
                sales_stages: true,
                account_owners: true,
                tags: true,
                distributors: true,
                cuisine_50: false,
                expense_category: false,
                rating: false,
                reviews_count: false,
                velocity_group: false,
                domain: false,
                instagram_url: false,
                instagram_followers: false,
                instagram_following: false,
                instagram_posts: false,
                instagram_percentile: false,
                instagram_text_percentile: false,
                ingredients_matrix: false,
                country_summary: false,
                state_summary: false,
                city_summary: false,
                zip_summary: false,
                median_hhi: false,
                pop_density: false,
                hh_gt100k: false,
                reputation_data: false,
                michelin_stars_count: false,
              }}
              tableKey={getCampaignTableKey(
                CHAINS_TABLE_KEY,
                props.campaign.id
              )}
              baseFilters={{ campaign: props.campaign.id }}
            />
          }
        />
      )
    case 'education-cu':
      return (
        <TableContainer
          campaign={props.campaign}
          selectedTab={props.selectedTab}
          onTabChange={props.onTabChange}
          key={`campaign-${props.campaign.id}`}
          oppsTable={
            <UniversitiesTable
              defaultColumnVisibility={{
                institution_name: true,
                address: false,
                city: false,
                state_abbr: false,
                zipcode: false,
                domain: false,
                dormitory_capacity: false,
                meal_plan_provided: false,
                total_enrollment: false,
                total_men_enrollment: false,
                total_women_enrollment: false,
                food_management_company: false,
                food_management_company_source: false,
                food_management_company_parent: false,
                estimated_meals_per_day: false,
                carnegie_classification_2021_size_setting_size: false,
                carnegie_classification_2021_size_setting_year: false,
                carnegie_classification_2021_size_setting_student_composition:
                  false,
                campaigns: false,
                deal_count: true,
                contact_count: true,
                note_count: true,
                sales_stages: true,
                account_owner: true,
                taglist: true,
                distributors: true,
                google_place_url: false,
              }}
              filterIdentifierModifier={
                `_CAMPAIGN_${props.campaign.id}` as FilterIdentifier
              }
              tableKey={getCampaignTableKey(
                COLLEGE_AND_UNIVERSITY_TABLE_KEY,
                props.campaign.id
              )}
              baseFilters={{ campaign: props.campaign.id }}
            />
          }
        />
      )
    case 'education-k-12':
      return (
        <TableContainer
          key={`campaign-${props.campaign.id}`}
          campaign={props.campaign}
          selectedTab={props.selectedTab}
          onTabChange={props.onTabChange}
          oppsTable={
            <K12Table
              filterIdentifierModifier={
                `_CAMPAIGN_${props.campaign.id}` as FilterIdentifier
              }
              tableKey={getCampaignTableKey(K12_TABLE_KEY, props.campaign.id)}
              baseFilters={{ campaign: props.campaign.id }}
              defaultColumnVisibility={{
                k12district__lea_name: true,
                k12district__address: false,
                k12district__zipcode: false,
                k12district__lcity: false,
                k12district__lstate: false,
                k12district__county: false,
                k12district__locale: false,
                k12district__operational_schools: false,
                k12district__gslo: false,
                k12district__gshi: false,
                k12district__student_count: false,
                k12district__teachers_count: false,
                k12district__phone: false,
                campaigns: false,
                sales_stages: true,
                deals__account_owner: true,
                taglist: true,
                contact_count: true,
                note_count: true,
                distributors: true,
                deal_count: true,
                k12district__revenue: false,
                k12district__reduced_lunch: false,
                k12district__free_lunch: false,
                k12district__estimated_meals_per_day: false,
                k12district__food_service_expenditures: false,
                k12district__total_supplemented_lunch: false,
                k12district__total_supplemented_lunch_perc: false,
                k12district__domain: false,
                k12district__google_place_url: false,
                k12district__uncategorized_lunch: false,
                k12district__free_lunch_perc: false,
                k12district__reduced_lunch_perc: false,
                k12district__uncategorized_lunch_perc: false,
              }}
            />
          }
        />
      )
    default:
      return (
        <TableContainer
          campaign={props.campaign}
          selectedTab={props.selectedTab}
          onTabChange={props.onTabChange}
          key={`campaign-${props.campaign.id}`}
          oppsTable={
            <CompaniesTable
              subCategory={type.sub_category}
              category={type.category}
              filterIdentifierModifier={
                `_CAMPAIGN_${props.campaign.id}` as FilterIdentifier
              }
              tableKey={getCampaignTableKey(
                getCompanyTableKey(type.slug as CompanyTypeSlug),
                props.campaign.id
              )}
              baseFilters={{ campaign: props.campaign.id }}
            />
          }
        />
      )
  }
}
function TableContainer(props: {
  oppsTable: React.JSX.Element
  campaign: Campaign
  selectedTab: string
  onTabChange: (tab: string) => void
}) {
  const hasContactRequests = useMemo(
    () =>
      ['restaurants-bars', 'education-cu', 'education-k12'].includes(
        props.campaign.type.slug
      ),
    [props.campaign]
  )

  const tabs = useMemo(() => {
    const tabs = [
      { title: 'Opportunities', key: 'opportunities' },
      { title: 'Deals', key: 'deals' },
      { title: 'Contacts', key: 'contacts' },
    ]
    if (hasContactRequests) {
      tabs.push({ title: 'Contact Requests', key: 'contact_requests' })
    }
    return tabs
  }, [hasContactRequests])

  return (
    <TableContainerDiv>
      <div className={'pt-3 px-4'}>
        <Tabs
          tabs={tabs as any}
          selectedTab={props.selectedTab}
          setSelectedTab={props.onTabChange}
          style={{
            borderBottom: 'none',
          }}
          tabStyle={{ width: '140px' }}
        />
      </div>
      <TabDiv show={props.selectedTab === 'opportunities'}>
        {props.oppsTable}
      </TabDiv>
      <TabDiv show={props.selectedTab === 'deals'}>
        <DealsTable
          showCompanyColumn
          disableUrlPagination
          filterIdentifierModifier={`_CAMPAIGN_${props.campaign.id}`}
          baseFilters={{ campaign: props.campaign.id }}
          tableKey={getCampaignTableKey(DEAL_TABLE_KEY, props.campaign.id)}
        />
      </TabDiv>
      <TabDiv show={props.selectedTab === 'contacts'}>
        <ContactsTable
          defaultColumnVisibility={{
            requested_contact__campaign__name: false,
          }}
          tableKey={getCampaignTableKey(CONTACTS_TABLE_KEY, props.campaign.id)}
          filterIdentifierModifier={`_CAMPAIGN_${props.campaign.id}`}
          baseFilters={{ campaign: props.campaign.id }}
        />
      </TabDiv>
      {hasContactRequests && (
        <TabDiv show={props.selectedTab === 'contact_requests'}>
          <ContactRequestsTable
            tableKey={getCampaignTableKey(
              CONTACT_REQUEST_JOB_TABLE_KEY,
              props.campaign.id
            )}
            campaignId={props.campaign.id}
          />
        </TabDiv>
      )}
    </TableContainerDiv>
  )
}

const TableContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  //overflow: hidden;
  height: calc(100vh - 236px);
`

const TabDiv = styled.div<{
  show?: boolean
}>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  height: 100%;
`
