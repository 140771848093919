import axios from 'axios'
import { AgeCard } from 'components/Cards/AgeCard'
import ChainHeaderCard from 'components/Cards/ChainHeaderCard'
import { EducationCard } from 'components/Cards/EducationCard'
import { RaceCard } from 'components/Cards/RaceCard'
import { ZipCodeDemographics } from 'components/Cards/ZipCodeDemographics'
import { CompanyAttributesManager } from 'components/CompanyAttrsManager/CompanyAttrsManager'
import NotFoundCard from 'components/NotFoundCard/NotFoundCard'
import useDocumentTitle from 'components/useDocumentTitle'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import apiService from 'services/api'
import { CARNEGIE_CLASSIFICATION_SIZE_OPTIONS } from 'utils/carnegieClassificationSize'
import { getIdWithPrefix } from 'utils/pathUtils'
import * as S from '../../../components/Cards/Cards.styles'
import ContactsTableSection from '../../../components/Cards/ContactsTableSection'
import DealsSection from '../../../components/Deals/DealsSection/DealsSection'
import { Page } from '../../../components/FbUI/Page'
import NotesSection from '../../../components/Notes/NotesSection/NotesSection'
import { Block } from '../../../components/UI/Block'
import { formatInteger } from '../../../utils/formatting'

export default function University() {
  const urlParams = useParams()
  const { preferences, isLoading: isLoadingPreferences } = usePreferences()
  const universityID = urlParams?.university_name
    ? getIdWithPrefix(
        urlParams?.university_name,
        preferences?.company_type_prefix_map[ContactCompanyType.University]
      )
    : undefined

  const api = apiService()
  const {
    data: universityData,
    isLoading,
    isError,
    error,
  } = api.useGetUniversity(universityID)

  useDocumentTitle(
    `${universityData?.university?.institution_name || 'Not Found'} (University) - First Bite`
  )

  const [notFound, setNotFound] = useState(false)

  // Check if the error is specifically a 404 not found error
  React.useEffect(() => {
    if (
      (!isLoadingPreferences && !universityID) ||
      (isError && axios.isAxiosError(error) && error?.response?.status === 404)
    ) {
      setNotFound(true)
    }
  }, [error, isError, universityID, universityID])

  if (notFound) {
    return <NotFoundCard />
  }

  if (isLoading) {
    return (
      <div className={'flex items-center justify-center h-96'}>
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <Page className="gap-y-4" noContainer>
      {universityData ? (
        <>
          <ChainHeaderCard
            type="university"
            contactCompanyId={universityData.id}
            isLoading={isLoading}
            category={'Non-Commercial'}
            subCategory={'University'}
            domainField={universityData?.university?.website_address.trim()}
            name={universityData?.university?.institution_name}
            breadcrumbs={[
              {
                title: 'University',
                breadcrumb: universityData?.university?.institution_name,
              },
            ]}
            address={{
              url: universityData?.university?.google_place_url,
              addressInfo: {
                fullAddress: universityData?.university?.address,
                city: universityData?.university?.city,
                state: universityData?.university?.state_abbr,
                zipcode: universityData?.university?.zipcode,
              },
            }}
            rightColumnHeader={
              <CompanyAttributesManager contactCompanyID={universityData.id} />
            }
            customBottomLeftItems={[
              {
                header: 'Carnegie Classification: Size',
                text:
                  CARNEGIE_CLASSIFICATION_SIZE_OPTIONS.find(
                    (option) =>
                      option.value ===
                      universityData?.university
                        ?.carnegie_classification_2021_size_setting
                  )?.label ?? 'Not available',
              },
              {
                header: 'Estimated Meals per Day',
                text: formatInteger(
                  universityData?.university?.estimated_meals_per_day
                ),
              },
              {
                header: 'Meal Plan Provided',
                text: universityData?.university?.meal_plan_provided
                  ? 'Yes ✅'
                  : 'No ⛔',
              },
            ]}
          />

          <Block className="h-[320px]">
            <S.Section>
              <S.SectionItem>
                <ZipCodeDemographics
                  addressInfo={universityData?.university?.address_info}
                />
              </S.SectionItem>

              <S.VerticalDivider />

              <S.SectionItem style={{ flex: 0.5 }}>
                <RaceCard
                  addressInfo={universityData?.university?.address_info}
                />
              </S.SectionItem>

              <S.VerticalDivider />

              <S.SectionItem>
                <AgeCard
                  addressInfo={universityData?.university?.address_info}
                />
              </S.SectionItem>

              <S.VerticalDivider />

              <S.SectionItem style={{ flex: 2 }}>
                <EducationCard
                  addressInfo={universityData?.university?.address_info}
                />
              </S.SectionItem>
            </S.Section>
          </Block>
        </>
      ) : (
        <div className={'flex items-center justify-center h-96'}>
          <Spinner animation="border" />
        </div>
      )}

      {universityData && (
        <NotesSection contactCompanyId={universityData.id} pagination={false} />
      )}

      {universityData && (
        <DealsSection
          forCompany={universityData}
          pagination={false}
          companyTypeSlug="education-cu"
        />
      )}

      {universityData && <ContactsTableSection data={universityData} />}
    </Page>
  )
}
