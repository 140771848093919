import apiService from 'services/api'
import { downloadFile } from 'utils/csvDownloader'
import ExportModal, { ExportModalProps } from './ExportModal'

export default function ChainExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportChains = async ({
    filter,
    selectedColumns,
    selectedProductId,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    selectedProductId?: number
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    const res = await api.downloadChainsCsvV2(
      {
        ...filter,
        product_id: selectedProductId,
        id: props.selectedIds,
      },
      selectedColumns,
      exportType,
      controller?.signal
    )

    downloadFile(
      res,
      `First Bite Export: Chains - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const columns = [
    {
      title: 'Opportunity Attributes',
      elements: [
        ['chain', 'Chain Name'],
        ['count', 'Chain Size'],
        ['ingredients_matrix', 'Menu Characteristics'],
        ['menu_length', 'Menu Size'],
        ['match_count', 'Menu Matches'],
        ['cuisine_50', 'Cuisine'],
        ['expense_category', 'Expense Category'],
        ['velocity_group', 'Predicted Traffic'],
        ['distributors', 'Known Distributors'],
        ['domain', 'Domain'],
        ['city_summary', 'City Summary'],
        ['state_summary', 'State Summary'],
        ['zip_summary', 'Zip Code Summary'],
        ['country_summary', 'Country Summary'],
        ['is_national', 'National'],
      ],
    },
    {
      title: 'Reputation Insights',
      elements: [
        ['avg_rating', 'Avg. Rating'],
        ['reviews_count', 'Total Reviews'],
        ['instagram_url', 'Instagram Handle'],
        ['instagram_followers', 'Instagram Followers'],
        ['instagram_following', 'Instagram Following'],
        ['instagram_posts', 'Instagram Posts'],
        ['instagram_percentile', 'Instagram Percentile'],
        ['instagram_text_percentile', 'Instagram Text Percentile'],
        ['featured_on', 'Featured On'],
        ['michelin_stars', 'Michelin Stars'],
      ],
    },
    {
      title: 'Predicted Values and Deals ',
      elements: [
        ['total_ltv', 'Lifetime Total Value'],
        ['brand_ltv', 'Lifetime Brand Value'],
        ['revenue_ltv', 'Lifetime Revenue Value'],
        ['taro_plus_tabo', '1yr Total Value'],
        ['tabo', '1yr Brand Value'],
        ['taro', '1yr Revenue Value'],
        ['pounds_per_year', '1yr Volume (lbs)'],
        ['sales_stages', 'Sales Stages'],
        ['account_owners', 'Account Owners'],
        ['tags', 'Tags'],
        ['deal_count', 'Deals Count'],
        ['note_count', 'Notes Count'],
        ['contact_count', 'Contacts Count'],
      ],
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Restaurant Chains Download"
      subTitle="Download up to 10,000 chains at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export Chains"
      columns={columns}
      exportAPIAction={exportChains}
      {...props}
    />
  )
}
