import { DealsTable } from '../../../components/Tables/DealsTable/DealsTable'
import React from 'react'
import { Page } from '../../../components/FbUI/Page'
import { StatsCard, StatsContainer } from '../../../styled/Stats'
import { formatInteger } from '../../../utils/formatting'
import { DEAL_TABLE_KEY } from 'constants/tableQueryKeys'
import {
  PageHeader,
  PageTitle,
  PageTitleRow,
} from 'components/FbUI/Page/styles'

export function DealsPage() {
  const [totalDeals, setTotalDeals] = React.useState<number>()

  const placesStats = [
    {
      name: 'Total Deals',
      value: formatInteger(totalDeals),
      isLoading: !totalDeals,
    },
  ]

  return (
    <Page>
      <PageHeader>
        <PageTitleRow>
          <PageTitle>Deals</PageTitle>
        </PageTitleRow>
        <StatsContainer>
          {placesStats.map((stat) => (
            <StatsCard key={stat.name}>
              <h1> {stat.name} </h1>
              {!stat.isLoading ? (
                <span> {stat.value} </span>
              ) : (
                <span style={{ fontSize: 12, fontWeight: 400 }}>
                  Calculating...
                </span>
              )}
            </StatsCard>
          ))}
        </StatsContainer>
      </PageHeader>
      <DealsTable
        tableKey={DEAL_TABLE_KEY}
        defaultColumnVisibility={{
          predicted_annual_revenue: false,
          predicted_monthly_revenue: false,
          monthly_revenue_override: false,
          company_size: false,
        }}
        showCompanyColumn
        setTotalRowsCount={setTotalDeals}
        containerStyle={{ height: 'calc(100vh - 236px)' }}
      />
    </Page>
  )
}
