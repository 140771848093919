import axios from 'axios'
import { AgeCard } from 'components/Cards/AgeCard'
import ChainHeaderCard from 'components/Cards/ChainHeaderCard'
import ContactsTableSection from 'components/Cards/ContactsTableSection'
import { EducationCard } from 'components/Cards/EducationCard'
import { RaceCard } from 'components/Cards/RaceCard'
import { ZipCodeDemographics } from 'components/Cards/ZipCodeDemographics'
import { CompanyAttributesManager } from 'components/CompanyAttrsManager/CompanyAttrsManager'
import NotFoundCard from 'components/NotFoundCard/NotFoundCard'
import useDocumentTitle from 'components/useDocumentTitle'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import apiService from 'services/api'
import { getIdWithPrefix } from 'utils/pathUtils'
import * as S from '../../components/Cards/Cards.styles'
import DealsSection from '../../components/Deals/DealsSection/DealsSection'
import { Page } from '../../components/FbUI/Page'
import NotesSection from '../../components/Notes/NotesSection/NotesSection'
import { Block } from '../../components/UI/Block'

export default function K12DistrictPage() {
  const urlParams = useParams()
  const { preferences, isLoading: isLoadingPreferences } = usePreferences()
  const k12ChainId = urlParams?.id
    ? getIdWithPrefix(
        urlParams?.id,
        preferences?.company_type_prefix_map[ContactCompanyType.K12District]
      )
    : undefined

  const api = apiService()
  const {
    data: data,
    isLoading,
    isError,
    error,
  } = api.useGetK12District(k12ChainId)

  useDocumentTitle(
    `${data?.k12district?.lea_name || 'Not Found'} (K12 School) - First Bite`
  )

  const [notFound, setNotFound] = useState(false)

  // Check if the error is specifically a 404 not found error
  React.useEffect(() => {
    if (
      (!isLoadingPreferences && !k12ChainId) ||
      (isError && axios.isAxiosError(error) && error?.response?.status === 404)
    ) {
      setNotFound(true)
    }
  }, [error, isError, k12ChainId, isLoadingPreferences])

  if (notFound) {
    return <NotFoundCard />
  }

  if (isLoading) {
    return (
      <div className={'flex items-center justify-center h-96'}>
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <Page className="gap-y-4" noContainer>
      {data ? (
        <>
          <ChainHeaderCard
            type="k12"
            contactCompanyId={data.id}
            isLoading={isLoading}
            category={'Non-Commercial'}
            subCategory={'Education K12'}
            domainField={data?.k12district?.website?.trim()}
            name={data?.k12district?.lea_name}
            breadcrumbs={[
              {
                title: 'K12 District',
                breadcrumb: data?.k12district?.lea_name,
              },
            ]}
            address={{
              url: data?.k12district?.google_place_url,
              addressInfo: {
                fullAddress: data?.k12district?.address,
                city: data?.k12district?.lcity,
                state: data?.k12district?.lstate,
                zipcode: data?.k12district?.zipcode,
              },
            }}
            rightColumnHeader={
              <CompanyAttributesManager contactCompanyID={data.id} />
            }
            customBottomLeftItems={[]}
          />

          {data?.k12district?.address_info && (
            <Block className="h-[320px]">
              <S.Section>
                <S.SectionItem>
                  <ZipCodeDemographics
                    addressInfo={data?.k12district.address_info}
                  />
                </S.SectionItem>

                <S.VerticalDivider />

                <S.SectionItem style={{ flex: 0.5 }}>
                  <RaceCard addressInfo={data?.k12district.address_info} />
                </S.SectionItem>

                <S.VerticalDivider />

                <S.SectionItem>
                  <AgeCard addressInfo={data?.k12district.address_info} />
                </S.SectionItem>

                <S.VerticalDivider />

                <S.SectionItem style={{ flex: 2 }}>
                  <EducationCard addressInfo={data?.k12district.address_info} />
                </S.SectionItem>
              </S.Section>
            </Block>
          )}
        </>
      ) : (
        <div className={'flex items-center justify-center h-96'}>
          <Spinner animation="border" />
        </div>
      )}

      {data && <NotesSection contactCompanyId={data.id} pagination={false} />}

      {data && (
        <DealsSection
          forCompany={data}
          pagination={false}
          companyTypeSlug="education-k-12"
        />
      )}

      {data && <ContactsTableSection data={data} />}
    </Page>
  )
}
