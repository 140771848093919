import { filterStoreRepo } from 'components/Filters/FilterStore'
import FilterModal, {
  FilterGroup,
  QuickFilterGroup,
} from 'components/Filters/components/FilterModal'
import {
  QuickRangeFilter,
  QuickValueFilter,
} from 'components/Filters/fields/QuickFilter'
import {
  BrandLTVRangeFilter,
  ChainSizeRangeFilter,
  DealCountRangeFilter,
  HighHouseholdIncomeRangeFilter,
  InstagramFollowersRangeFilter,
  LTVTotalRangeFilter,
  MedianHouseholdIncomeRangeFilter,
  PopDensityRangeFilter,
  PoundsPerYearRangeFilter,
  RangeFilter,
  RangeFilterWithMinMax,
  RevenueLTVRangeFilter,
  ReviewCountRangeFilter,
  TaboRangeFilter,
  TaroPlusTaboRangeFilter,
  TaroRangeFilter,
} from 'components/Filters/fields/RangeFilter'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { ZipcodeFilter } from 'components/Filters/fields/ZipcodeFilter'
import { menu_ingredients } from 'models/menu_ingredients'
import { REPUTATION_PLATFORMS_OPTIONS } from 'models/reputation_platforms'
import { VelocityGroupOptions } from 'models/velocity_groups'
import { InstagramTextPercentileOptions } from 'models/instagram_text_percentile'
import { useEffect, useState } from 'react'
import apiService from 'services/api'
import { ALL_COUNTRIES } from 'utils/countries'
import { featureFlagService } from 'utils/featureFlagService'
import { US_CITIES } from 'utils/us_cities'
import { US_STATES } from 'utils/us_states'
import { Cuisine50Options } from '../../../models/cuisine_50'
import { FILTER_TITLES } from '../../Filters/filterTitles'

import { FilterIdentifier } from 'models/saved_view'

export function ChainsFilterset({
  filterIdentifier,
}: {
  filterIdentifier: FilterIdentifier
}) {
  const chainsFilterStore = filterStoreRepo.getStore(filterIdentifier)
  const { pendingValueFilters, setPendingValueFilters } = chainsFilterStore()

  const api = apiService()
  const featureFlags = featureFlagService()
  const { data: userOptions } = api.useGetUserOptions(true)
  const { data: stageOptions } = api.useGetSalesStageOptions(true)
  const { data: tagsOptions } = api.useGetTagsOptions(true)
  const { data: distributorOptions } =
    api.useGetCompanyDistributorsOptions(true)

  const [usCities, setUsCities] = useState<{ value: string; label: string }[]>()

  useEffect(() => {
    const selectedStates = pendingValueFilters.state?.map((f) => f.value)
    if (!selectedStates?.length) {
      setUsCities(US_CITIES)
      return
    }

    const availableCities = US_CITIES.filter((c) =>
      selectedStates.some((s) => s === c.value.split('_')[1])
    )

    const selectedCities = pendingValueFilters?.city?.map((f) => f.value)

    if (selectedCities) {
      const citiesToRemove: string[] = []
      for (const city of selectedCities) {
        if (!availableCities.some((c) => c.value === city)) {
          citiesToRemove.push(city as string)
        }
      }
      if (citiesToRemove.length) {
        setPendingValueFilters(
          'city',
          pendingValueFilters.city.filter(
            (f) => !citiesToRemove.some((c) => c === f.value)
          )
        )
      }
    }

    setUsCities(availableCities)
  }, [pendingValueFilters])

  return (
    <FilterModal identifier={filterIdentifier} store={chainsFilterStore}>
      <QuickFilterGroup>
        <QuickRangeFilter
          filterKey="one_year_total_value"
          title={FILTER_TITLES[filterIdentifier].one_year_total_value}
          valueLabel="≥1"
          predefinedValue={{
            min: 1,
          }}
          filterStore={chainsFilterStore}
        />
        <QuickRangeFilter
          filterKey="chain_count"
          title={FILTER_TITLES[filterIdentifier].chain_count}
          valueLabel="2+"
          predefinedValue={{
            min: 2,
          }}
          filterStore={chainsFilterStore}
        />
        <QuickValueFilter
          title={FILTER_TITLES[filterIdentifier].sales_stage}
          valueLabel="Empty"
          filterKey="sales_stage"
          predefinedValue={[
            {
              value: '-1',
              label: 'Empty',
            },
          ]}
          filterStore={chainsFilterStore}
        />
        <QuickRangeFilter
          filterKey="contact_count"
          title={FILTER_TITLES[filterIdentifier].contact_count}
          valueLabel="≤0"
          predefinedValue={{
            max: 0,
          }}
          filterStore={chainsFilterStore}
        />
      </QuickFilterGroup>

      <FilterGroup title="Lifetime Values">
        <LTVTotalRangeFilter store={chainsFilterStore} />
        <BrandLTVRangeFilter store={chainsFilterStore} />
        <RevenueLTVRangeFilter store={chainsFilterStore} />
      </FilterGroup>

      <FilterGroup title="Predictive Annual Values">
        <TaroPlusTaboRangeFilter store={chainsFilterStore} />
        <TaroRangeFilter store={chainsFilterStore} />
        <TaboRangeFilter store={chainsFilterStore} />
      </FilterGroup>

      <FilterGroup title="Menu">
        <SelectFilter
          filterStore={chainsFilterStore}
          filterKey={'cuisine_50'}
          title={FILTER_TITLES[filterIdentifier].cuisine_50}
          options={Cuisine50Options}
        />
        <PoundsPerYearRangeFilter store={chainsFilterStore} />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].menu_ingredients}
          filterStore={chainsFilterStore}
          filterKey={'menu_ingredients'}
          options={menu_ingredients}
        />
        {featureFlags.shouldShowMenuSize && (
          <RangeFilter
            filterKey="menu_length"
            title={FILTER_TITLES[filterIdentifier].menu_length}
            store={chainsFilterStore}
          />
        )}
        <RangeFilter
          filterKey="menu_matches"
          title={FILTER_TITLES[filterIdentifier].menu_matches}
          store={chainsFilterStore}
        />
      </FilterGroup>

      <FilterGroup title="Features">
        <ChainSizeRangeFilter store={chainsFilterStore} />
        <SelectFilter
          filterStore={chainsFilterStore}
          filterKey={'is_chain'}
          title={FILTER_TITLES[filterIdentifier].is_chain}
          options={[
            { label: 'Yes', value: 'True' },
            { label: 'No', value: 'False' },
          ]}
        />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].expense_category}
          filterStore={chainsFilterStore}
          filterKey={'expense_category'}
          options={[
            { label: '$', value: '1' },
            { label: '$$', value: '2' },
            { label: '$$$', value: '3' },
            { label: '$$$$', value: '4' },
          ]}
        />
        {featureFlags.shouldShowReputationData && (
          <>
            <SelectFilter
              title={FILTER_TITLES[filterIdentifier].reputation_data}
              options={REPUTATION_PLATFORMS_OPTIONS}
              filterStore={chainsFilterStore}
              filterKey={'reputation_data'}
            />
            <RangeFilterWithMinMax
              filterKey="michelin_stars"
              title={FILTER_TITLES[filterIdentifier].michelin_stars}
              store={chainsFilterStore}
              min={0}
              max={3}
            />
          </>
        )}
        <RangeFilterWithMinMax
          filterKey="rating"
          title={FILTER_TITLES[filterIdentifier].rating}
          min={0}
          max={5}
          decimalScale={2}
          store={chainsFilterStore}
        />
        <ReviewCountRangeFilter store={chainsFilterStore} />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].velocity_group}
          options={VelocityGroupOptions}
          filterStore={chainsFilterStore}
          filterKey={'velocity_group'}
        />
        {featureFlags.enableInstagremData && (
          <InstagramFollowersRangeFilter store={chainsFilterStore} />
        )}
        {featureFlags.enableInstagramPercentile && (
          <RangeFilter
            filterKey="instagram_percentile"
            title={FILTER_TITLES[filterIdentifier].instagram_percentile}
            store={chainsFilterStore}
          />
        )}
        {featureFlags.enableInstagramPercentile && (
          <SelectFilter
            title={FILTER_TITLES[filterIdentifier].instagram_text_percentile}
            options={InstagramTextPercentileOptions}
            filterStore={chainsFilterStore}
            filterKey={'instagram_text_percentile'}
          />
        )}
        {/*<SelectFilter*/}
        {/*  filterStore={chainsFilterStore}*/}
        {/*  filterKey={'is_national'}*/}
        {/*  title="National Chain"*/}
        {/*  options={[*/}
        {/*    { label: 'Yes', value: 'True' },*/}
        {/*    { label: 'No', value: 'False' },*/}
        {/*  ]}*/}
        {/*/>*/}
      </FilterGroup>

      <FilterGroup title="Account">
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].sales_stage}
          options={stageOptions as any}
          filterStore={chainsFilterStore}
          filterKey={'sales_stage'}
        />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].account_owner}
          options={userOptions as any}
          filterStore={chainsFilterStore}
          filterKey={'account_owner'}
        />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].tags}
          options={tagsOptions as any}
          filterStore={chainsFilterStore}
          filterKey={'tags'}
        />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].distributors}
          options={distributorOptions as any}
          filterStore={chainsFilterStore}
          filterKey={'distributors'}
        />
        <DealCountRangeFilter store={chainsFilterStore} />
        <RangeFilter
          store={chainsFilterStore}
          filterKey="contact_count"
          title={FILTER_TITLES[filterIdentifier].contact_count}
        />
      </FilterGroup>

      <FilterGroup title="Location">
        {featureFlags.enableRestaurantsCountryFilter && (
          <SelectFilter
            title={FILTER_TITLES[filterIdentifier].country}
            filterStore={chainsFilterStore}
            filterKey={'country'}
            options={ALL_COUNTRIES}
          />
        )}

        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].state}
          options={US_STATES}
          filterStore={chainsFilterStore}
          filterKey={'state'}
        />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].city}
          filterStore={chainsFilterStore}
          filterKey={'city'}
          options={usCities}
        />
        <ZipcodeFilter filterStore={chainsFilterStore} />
      </FilterGroup>

      <FilterGroup title="ZIP Code Demographics">
        <MedianHouseholdIncomeRangeFilter store={chainsFilterStore} />
        <HighHouseholdIncomeRangeFilter store={chainsFilterStore} />
        <PopDensityRangeFilter store={chainsFilterStore} />
      </FilterGroup>
    </FilterModal>
  )
}
