import { useMemo } from 'react'
import { FakeLink } from '../../../FbUI/FbLink/styles'
import { FilterChipZipRange } from './FilterChipZipRange'
import { FilterChipValue } from './FilterChipValue'
import {
  FilterCriteria,
  FilterStore,
  RangeFilterState,
  SearchFilterState,
  ValueFilterState,
  ZipCodeFilterState,
} from '../../FilterStore'
import { FilterChipRange } from './FilterChipRange'
import { FilterChipSearch } from './FilterChipSearch'
import { FilterIdentifier } from 'models/saved_view'
import { FILTER_TITLES } from '../../filterTitles'
import { cn } from 'components/UI/cn'

export function FilterChips({
  clearAllButton = false,
  showActive = false,
  store,
  identifier,
  className,
}: {
  clearAllButton?: boolean
  showActive?: boolean
  store: FilterStore
  identifier: FilterIdentifier
  className?: string
}) {
  const {
    pendingCriteria,
    criteria,
    pendingValueFilters,
    valueFilters,
    rangeFilters,
    pendingRangeFilters,
    clearPendingValueFilter,
    clearValueFilter,
    clearPendingRangeFilter,
    clearRangeFilter,
    clearAllFilters,
    clearAllPendingFilters,
    pendingZipCodeFilter,
    zipCodeFilter,
    clearPendingZipCodeFilter,
    clearZipCodeFilter,
    search,
    pendingSearch,
    clearSearch,
    clearPendingSearch,
  } = store()

  let _valueFilters = pendingValueFilters
  let _rangeFilters = pendingRangeFilters
  let _zipCodeFilter = pendingZipCodeFilter
  let _clearValueFilter = clearPendingValueFilter
  let _clearRangeFilter = clearPendingRangeFilter
  let _search = pendingSearch
  let _clearSearch = clearPendingSearch
  let _criteria = pendingCriteria
  let _clearAll = clearAllPendingFilters
  let _clearZipCodeFilter = clearPendingZipCodeFilter

  if (showActive) {
    _valueFilters = valueFilters
    _rangeFilters = rangeFilters
    _zipCodeFilter = zipCodeFilter
    _clearValueFilter = clearValueFilter
    _clearRangeFilter = clearRangeFilter
    _search = search
    _clearSearch = clearSearch
    _criteria = criteria
    _clearAll = clearAllFilters
    _clearZipCodeFilter = clearZipCodeFilter
  }

  return (
    <FilterChipsComponent
      className={className}
      filterState={{
        valueFilters: _valueFilters,
        rangeFilters: _rangeFilters,
        zipCodeFilter: _zipCodeFilter,
        search: _search,
        criteria: _criteria,
      }}
      actions={{
        clearValueFilter: _clearValueFilter,
        clearSearch: _clearSearch,
        clearAll: clearAllButton ? _clearAll : undefined,
        clearZipCodeFilter: _clearZipCodeFilter,
        clearRangeFilter: _clearRangeFilter,
      }}
      identifier={identifier}
    />
  )
}

export function FilterChipsComponent({
  filterState,
  actions,
  identifier,
  className,
}: {
  filterState: {
    valueFilters: ValueFilterState
    rangeFilters: RangeFilterState
    zipCodeFilter: ZipCodeFilterState
    search: SearchFilterState
    criteria: FilterCriteria
  }
  actions?: {
    clearZipCodeFilter?: () => void
    clearRangeFilter?: (key: string) => void
    clearValueFilter?: (key: string) => void
    clearSearch?: (key: string) => void
    clearAll?: () => void
  }
  identifier: FilterIdentifier
  className?: string
}) {
  const hasFilters = useMemo(
    () =>
      Object.entries(filterState.valueFilters).length > 0 ||
      Object.entries(filterState.rangeFilters).length > 0 ||
      Object.values(filterState.search).filter((s) => !!s.value).length > 0 ||
      filterState.zipCodeFilter[0],
    [filterState]
  )

  if (!hasFilters) return null

  return (
    <div
      className={cn(
        'd-flex flex-wrap align-items-center gap-2 ml-auto mt-2',
        className
      )}
    >
      {filterState.zipCodeFilter[0] && (
        <FilterChipZipRange
          label="Zip Code"
          active={filterState.zipCodeFilter}
          isExclusive={filterState.criteria === 'neither'}
          onClose={
            actions?.clearZipCodeFilter
              ? () => actions?.clearZipCodeFilter?.()
              : undefined
          }
        />
      )}

      {Object.entries(filterState.rangeFilters).map(([key, value]) => {
        return (
          <FilterChipRange
            key={key}
            filterCriteria={filterState.criteria}
            title={FILTER_TITLES?.[identifier]?.[key]}
            onClose={
              actions?.clearRangeFilter
                ? () => actions?.clearRangeFilter?.(key)
                : undefined
            }
            value={value}
          />
        )
      })}

      {Object.entries(filterState.valueFilters).map(([key, value]) => (
        <FilterChipValue
          key={key}
          filterCriteria={filterState.criteria}
          title={FILTER_TITLES?.[identifier]?.[key]}
          onClose={
            actions?.clearRangeFilter
              ? () => actions?.clearValueFilter?.(key)
              : undefined
          }
          value={value}
        />
      ))}

      {Object.keys(filterState.search).map((key) => (
        <FilterChipSearch
          key={key}
          search={filterState.search[key].value}
          searchLabel={filterState.search[key].label}
          onClose={
            actions?.clearSearch ? () => actions?.clearSearch?.(key) : undefined
          }
        />
      ))}

      {actions?.clearAll && hasFilters && (
        <FakeLink className={'ml-2'} onClick={actions?.clearAll}>
          Clear all
        </FakeLink>
      )}
    </div>
  )
}
