import { QueryClientProvider } from '@tanstack/react-query'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ChainPage } from 'pages/app/chain-page'
import Door from 'pages/app/door'
import University from 'pages/app/university'
import React, { ReactNode, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { AuthProvider } from './context/authentication/auth-context'
import { queryClient } from './services/queryClient'

import * as Sentry from '@sentry/react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import 'chart.js/auto'
import { PreferencesProvider } from 'context/preferences/PreferencesContext'
import { ActivityPage } from 'pages/app/activity/activity'
import { ActivityPageV2 } from 'pages/app/activity/activityV2'
import { SingleCampaignPage } from 'pages/app/campaigns/single-campaign-page'
import { CompanyPage } from 'pages/app/company'
import { ContactsPage } from 'pages/app/contacts/contacts'
import { Dashboard } from 'pages/app/dashboard'
import { ProductPage } from 'pages/app/product'
import { Settings } from 'pages/app/settings'
import Failed from 'pages/auth/failed'
import { CommonLayout } from 'pages/common/layout'
import Privacy from 'pages/common/privacy'
import Terms from 'pages/common/terms'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { featureFlagService } from 'utils/featureFlagService'
import { PageWithTitle } from 'utils/PageWithTitle'
import { APP_VERSION } from 'version'
import LoadingScreen from './components/Layout/LoadingScreen'
import { useAuth } from './context/authentication/useAuth'
import './main.css'
import { CampaignsPage } from './pages/app/campaigns/campaigns-page'
import { DealsPage } from './pages/app/deals/deals'
import K12DistrictPage from './pages/app/k12-district-page'
import { AppLayout } from './pages/app/layout'
import { OpportunitiesPage } from './pages/app/opportunities/opportunities'
import { AuthLayout } from './pages/auth/layout'
import Login from './pages/auth/login'
import Register from './pages/auth/register'
import ResetPassword from './pages/auth/reset-password'
import ResetPasswordConfirm from './pages/auth/reset-password-confirm'
import Verified from './pages/auth/verified'
import NotFound from './pages/not-found'
import apiService from './services/api'

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    dsn: 'https://bdb286552bd38381f647c5dc31f4fa98@o4505942099230720.ingest.sentry.io/4505942563553280',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 0.5,
    environment: import.meta.env.MODE,
    release: APP_VERSION,
  })
}

const sentryCreateBrowserRouter =
  import.meta.env.MODE === 'development'
    ? createBrowserRouter
    : Sentry.wrapCreateBrowserRouter(createBrowserRouter)

function Root() {
  const api = apiService()
  const { isAuthenticated, isCheckingAuth } = useAuth()

  // Common data shared across the app
  // We load it here so its available faster
  api.useGetCompanyCategoriesAndSub(!isAuthenticated)
  api.useGetUsers(!isAuthenticated)
  api.useGetSalesStages(!isAuthenticated)
  api.useGetTags('products', !isAuthenticated)
  api.useGetTags('contacts', !isAuthenticated)
  api.useGetProducts(!isAuthenticated)
  api.useGetCampaigns(undefined, !isAuthenticated)

  const { enableActivityV2, isLoading } = featureFlagService()

  useEffect(() => {
    // If this is a production build, load the ga4 script
    if (process.env.NODE_ENV === 'production') {
      const src_script = document.createElement('script')
      src_script.src =
        'https://www.googletagmanager.com/gtag/js?id=G-2DXQWDV89Z'
      src_script.async = true

      const script = document.createElement('script')
      script.innerHTML =
        " window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-2DXQWDV89Z'); "

      document.head.appendChild(src_script)
      document.head.appendChild(script)

      return () => {
        document.head.removeChild(src_script)
        document.head.removeChild(script)
      }
    }
  }, [])

  if (isCheckingAuth || isLoading) {
    return <LoadingScreen />
  }

  const appRouter = sentryCreateBrowserRouter([
    {
      path: '*',
      element: (
        <ProtectedRoute>
          <NotFound />
        </ProtectedRoute>
      ),
    },
    {
      path: '',
      element: (
        <ProtectedRoute>
          <RedirectRestaurants />
        </ProtectedRoute>
      ),
    },
    {
      path: '/',
      element: <AppLayout />,
      errorElement: <NotFound />,
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <RedirectRestaurants />
            </ProtectedRoute>
          ),
        },
        {
          path: 'dashboard',
          element: (
            <ProtectedRoute>
              <PageWithTitle title="Dashboard">
                <Dashboard />
              </PageWithTitle>
            </ProtectedRoute>
          ),
        },
        {
          path: 'contacts/:type?/',
          element: (
            <ProtectedRoute>
              <PageWithTitle title="Contacts">
                <ContactsPage />
              </PageWithTitle>
            </ProtectedRoute>
          ),
        },
        {
          path: 'activity/:type?/',
          element: (
            <ProtectedRoute>
              <PageWithTitle title="Activity">
                {enableActivityV2 ? <ActivityPageV2 /> : <ActivityPage />}
              </PageWithTitle>
            </ProtectedRoute>
          ),
        },
        {
          path: 'company/:company_id',
          element: (
            <ProtectedRoute>
              <PageWithTitle title="Company">
                <CompanyPage />
              </PageWithTitle>
            </ProtectedRoute>
          ),
        },
        {
          path: 'opportunities/',
          element: (
            <ProtectedRoute>
              <RedirectRestaurants />
            </ProtectedRoute>
          ),
        },
        {
          path: 'opportunities/:category/:sub_category/',
          element: (
            <ProtectedRoute>
              <PageWithTitle title="Opportunities">
                <OpportunitiesPage />
              </PageWithTitle>
            </ProtectedRoute>
          ),
        },
        {
          path: 'door/:firstbite_id',
          element: (
            <ProtectedRoute>
              <PageWithTitle title="Door">
                <Door />
              </PageWithTitle>
            </ProtectedRoute>
          ),
        },
        {
          path: 'chain/:chain_name',
          element: (
            <ProtectedRoute>
              <PageWithTitle title="Chain">
                <ChainPage />
              </PageWithTitle>
            </ProtectedRoute>
          ),
        },
        {
          path: 'university/:university_name',
          element: (
            <ProtectedRoute>
              <PageWithTitle title="University">
                <University />
              </PageWithTitle>
            </ProtectedRoute>
          ),
        },
        {
          path: 'k12/:id/',
          element: (
            <ProtectedRoute>
              <PageWithTitle title="K12 District">
                <K12DistrictPage />
              </PageWithTitle>
            </ProtectedRoute>
          ),
        },
        {
          path: 'product',
          element: (
            <ProtectedRoute>
              <PageWithTitle title="Products">
                <ProductPage />
              </PageWithTitle>
            </ProtectedRoute>
          ),
        },
        {
          path: 'settings/:type?/',
          element: (
            <ProtectedRoute>
              <PageWithTitle title="Settings">
                <Settings />
              </PageWithTitle>
            </ProtectedRoute>
          ),
        },
        {
          path: 'deals',
          element: (
            <ProtectedRoute>
              <PageWithTitle title="Deals">
                <DealsPage />
              </PageWithTitle>
            </ProtectedRoute>
          ),
        },
        {
          path: 'campaigns',
          element: (
            <ProtectedRoute>
              <PageWithTitle title="Campaigns">
                <CampaignsPage />
              </PageWithTitle>
            </ProtectedRoute>
          ),
        },
        {
          path: 'campaigns/:campaign_id/:tab?',
          element: (
            <ProtectedRoute>
              <SingleCampaignPage />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/login',
      element: <AuthLayout />,
      children: [
        {
          path: '',
          element: (
            <PageWithTitle title="Log-in">
              <Login />
            </PageWithTitle>
          ),
        },
      ],
    },
    {
      path: '/signup',
      element: <AuthLayout />,
      children: [
        {
          path: '',
          element: (
            <PageWithTitle title="Sign-up">
              <Register />
            </PageWithTitle>
          ),
        },
      ],
    },
    {
      path: '/email-verified-confirmation',
      element: <AuthLayout />,
      children: [
        {
          path: '',
          element: (
            <PageWithTitle title="Email Verified">
              <Verified />
            </PageWithTitle>
          ),
        },
      ],
    },
    {
      path: '/email-verification-failed',
      element: <AuthLayout />,
      children: [
        {
          path: '',
          element: (
            <PageWithTitle title="Email Verification Failed">
              <Failed />
            </PageWithTitle>
          ),
        },
      ],
    },
    {
      path: '/reset-password',
      element: <AuthLayout />,
      children: [
        {
          path: '',
          element: (
            <PageWithTitle title="Reset Password">
              <ResetPassword />
            </PageWithTitle>
          ),
        },
      ],
    },
    {
      path: '/password-reset-confirm/:uid/:token',
      element: <AuthLayout />,
      children: [
        {
          path: '',
          element: (
            <PageWithTitle title="Reset Password Confirmation">
              <ResetPasswordConfirm />
            </PageWithTitle>
          ),
        },
      ],
    },
    {
      path: '/terms-of-use',
      element: <CommonLayout />,
      errorElement: <CommonLayout />,
      children: [
        {
          path: '',
          element: (
            <PageWithTitle title="Terms of Use">
              <Terms />
            </PageWithTitle>
          ),
          errorElement: <>Teste</>,
        },
      ],
    },
    {
      path: '/privacy-policy',
      element: <CommonLayout />,
      children: [
        {
          path: '',
          element: (
            <PageWithTitle title="Privacy Policy">
              <Privacy />
            </PageWithTitle>
          ),
        },
      ],
    },
  ])

  return <RouterProvider router={appRouter} />
}

function RedirectRestaurants() {
  return <Navigate to={'/opportunities/commercial/restaurants-bars/'} />
}

function ProtectedRoute(props: { children: ReactNode }) {
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    return <Navigate to={'/login'} />
  }

  return props.children
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <PreferencesProvider>
        <Root />
        <ReactQueryDevtools />
        <ToastContainer />
      </PreferencesProvider>
    </QueryClientProvider>
  </AuthProvider>
)
