import { useQuery } from '@tanstack/react-query'
import TableButtonAction from 'components/Buttons/TableButtons/TableButtonAction'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import DistributorAssignModal from 'components/Modals/AssignmentModal/DistributorAssignModal'
import TagAssignModal from 'components/Modals/AssignmentModal/TagAssignModal'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import {
  CreateContactRequestJobModal,
  MAX_OPPORTUNITIES_REQUESTED,
} from 'components/Modals/CreateContactRequestJobModal'
import UniversityExportModal from 'components/Modals/ExportModal/UniversityExportModal'
import OpportunitiesTableFooterControls from 'components/Opportunities/OpportunitiesTableFooterControls'
import { ContactResponse } from 'models/contacts'
import React, { useEffect, useMemo, useState } from 'react'
import {
  BsBriefcase,
  BsDownload,
  BsPersonAdd,
  BsTags,
  BsTruck,
  BsXCircle,
} from 'react-icons/bs'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import styled from 'styled-components/macro'
import { featureFlagService } from 'utils/featureFlagService'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { AssignToCampaignModal } from '../../../features/campaigns/AssignToCampaignModal'
import { useCampaignUnassigner } from '../../../features/campaigns/useCampaignUnassigner'
import { FilterIdentifier } from '../../../models/saved_view'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import { IColumnVisibility } from '../../DataTable/types'
import { CreateDealModalForTable } from '../../Deals/CreateDealModal/CreateDealModal'
import { TableSearch } from '../../Filters/TableSearch'
import { FilterChips } from '../../Filters/components/FilterChips/FilterChips'
import { IDropdownItem } from '../../UI/Dropdown/Dropdown'
import * as S from '../CommonTable.styles'
import { UniversitiesFilterset } from './UniversitiesFilterset'
import { useUniversitiesTableCols } from './universitiesTableColumns'

import { cn } from '../../UI/cn'

type SortableFields = (keyof ContactResponse | string)[]

const sortableFields: SortableFields = [
  'institution_name',
  'total_students_enrolled',
  'note_count',
  'contact_count',
  'deal_count',
  'address',
  'city',
  'state_abbr',
  'zipcode',
  'domain',
  'dormitory_capacity',
  'meal_plan_provided',
  'total_enrollment',
  'food_management_company',
  'food_management_company_source',
  'food_management_company_parent',
  'estimated_meals_per_day',
  'carnegie_classification_2021_size_setting_size',
  'carnegie_classification_2021_size_setting_year',
  'carnegie_classification_2021_size_setting_student_composition',
  'sales_stages',
]

const PAGE_SIZE = 100

interface UniversitiesTableProps {
  setTotalRowsCount?: (count: number) => void
  pageParamName?: string
  baseFilters?: Record<string, any>
  tableKey: string
  filterIdentifierModifier?: FilterIdentifier
}

function UniversitiesTableComponent(props: UniversitiesTableProps) {
  const api = apiService()
  const featureFlag = featureFlagService()
  const {
    state: { sorting, rowSelection, isAllRowsSelected, totalSelectedRows },
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = useDataTableContext()

  const IDENTIFIER = 'CNU_TABLE' + (props.filterIdentifierModifier ?? '')
  const universitiesFilterStore = filterStoreRepo.getStore(
    IDENTIFIER as FilterIdentifier
  )

  const hasCampaign = !!props.baseFilters?.campaign

  const [pagination, setPagination] = usePaginationURLParams(
    PAGE_SIZE,
    props.pageParamName
  )

  const filterParams = {
    ...useFilterParams(universitiesFilterStore),
    ...props.baseFilters,
  }

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}
    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }
    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...filterParams,
      ...sortParams,
    }),
    [filterParams, sortParams]
  )

  const totalRowsSelected = useMemo(() => {
    return isAllRowsSelected
      ? totalSelectedRows
      : Object.keys(rowSelection).length
  }, [isAllRowsSelected, rowSelection])

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
  })

  // DATA FETCHING
  const { isFetching, data, refetch } = useQuery({
    staleTime: Infinity,
    queryKey: TABLE_QUERY_KEY,
    queryFn: async () => {
      clearSelectedRows()

      const res = await api.getUniversitiesList({
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        ...filterAndSortParams,
      })
      props.setTotalRowsCount?.(res.count)
      setTotalRowsInBackend(res.count)
      return res
    },
  })
  // END DATA FETCHING

  const unassignFromCampaign = useCampaignUnassigner('cnu', {
    data: data?.results ?? [],
    accessorKey: 'id',
    tableQueryKey: TABLE_QUERY_KEY,
    requestParams: filterAndSortParams,
    campaignId: props?.baseFilters?.campaign,
  })

  useEffect(() => {
    clearSelectedRows()
  }, [data])

  const [showAssignTags, setShowAssignTags] = useState(false)
  const [showAssignDistributors, setShowAssignDistributors] = useState(false)
  const [openDealModal, setOpenDealModal] = React.useState(false)
  const [dealCompanyIds, setDealCompanyIds] = React.useState<number[]>([])
  const [showContactRequest, setShowContactRequest] = useState(false)
  const [showExportAllModal, setShowExportAllModal] = useState(false)
  const handleCreateDeals = () => {
    if (!data) return
    const companyIds = Object.keys(rowSelection).map(
      (idx) => data.results[parseInt(idx)].id
    )
    setDealCompanyIds(companyIds)
    setOpenDealModal(true)
  }

  const columns = useUniversitiesTableCols({ sorting })

  useEffect(() => {
    const totalCount = data?.count ?? 0
    props.setTotalRowsCount?.(totalCount)
    setTotalRowsInBackend?.(totalCount)
  }, [data?.count])

  const actions = useMemo(() => {
    const items: IDropdownItem[] = [
      {
        label: 'Create Deals',
        icon: <BsBriefcase size={20} />,
        callback: handleCreateDeals,
      },
      {
        label: 'Manage Tags',
        icon: <BsTags size={20} />,
        callback: () => setShowAssignTags(true),
      },
      {
        label: 'Add Known Distributors',
        icon: <BsTruck size={20} />,
        callback: () => setShowAssignDistributors(true),
      },
    ]

    if (featureFlag.enableContactRequests) {
      items.push({
        label: 'Request Contacts',
        icon: <BsPersonAdd size={20} />,
        callback: () => setShowContactRequest(true),
        disabled: totalRowsSelected > MAX_OPPORTUNITIES_REQUESTED,
        disabledTooltipText: `To request contacts, select less than ${MAX_OPPORTUNITIES_REQUESTED} opportunities.`,
      })
    }

    if (featureFlag.enableCampaigns) {
      if (!hasCampaign) {
        items.push({
          label: 'Push to Campaign',
          icon: <BsDownload size={20} />,
          subContent: (
            <AssignToCampaignModal
              data={data?.results ?? []}
              accessorKey={'id'}
              tableQueryKey={TABLE_QUERY_KEY}
              requestParams={filterAndSortParams}
              companyTypeSlug={'education-cu'}
              buttonLess
            />
          ),
        })
      } else {
        items.push({
          label: 'Unassign from Campaign',
          icon: <BsXCircle size={20} />,
          callback: () => unassignFromCampaign(),
        })
      }
    }

    return items
  }, [data, featureFlag, hasCampaign])

  return (
    <TableContainer>
      <S.SearchContainer>
        <div className="flex w-full gap-2 items-center">
          <TableSearch
            filterStore={universitiesFilterStore}
            searchPlaceholder="Search universities by name"
            className="flex-1"
          />

          <UniversitiesFilterset
            filterIdentifier={IDENTIFIER as FilterIdentifier}
          />

          <ColumnSelectorRecipient tableKey={props.tableKey} />
        </div>

        <div className={cn('flex flex-row justify-between gap-4')}>
          <TableButtonAction
            items={actions}
            disabled={!totalSelectedRows}
            selectedRowsCount={totalSelectedRows ?? 0}
          />
          <FilterChips
            identifier={IDENTIFIER as FilterIdentifier}
            clearAllButton
            showActive
            store={universitiesFilterStore}
          />
        </div>
      </S.SearchContainer>

      <CreateContactRequestJobModal
        data={data?.results ?? []}
        open={showContactRequest}
        onClose={() => setShowContactRequest(false)}
        accessorKeys={['id']}
        tableQueryKey={TABLE_QUERY_KEY}
        requestParams={filterAndSortParams}
        companyTypeSlug={'education-cu'}
      />

      <TagAssignModal
        show={showAssignTags}
        handleClose={() => setShowAssignTags(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        filterAndSortParams={filterAndSortParams}
        apiTagsAction={api.universitiesBulkEditTags}
        idAccessor="id"
      />

      <DistributorAssignModal
        show={showAssignDistributors}
        handleClose={() => setShowAssignDistributors(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="id"
        filterAndSortParams={filterAndSortParams}
        apiDistributorsAction={api.universitiesBulkEditDistributors}
      />

      <UniversityExportModal
        count={data?.count ?? 0}
        show={showExportAllModal}
        _handleClose={() => {
          setShowExportAllModal(false)
        }}
        filters={{
          ...filterAndSortParams,
        }}
      />

      <DataTable
        tableKey={props.tableKey}
        autoLayout
        loading={isFetching}
        data={data?.results ?? []}
        columns={columns}
        sortableFields={sortableFields}
        virtualizeRows={true}
        enableRowSelection
        isPaginationEnabled={true}
        paginationOptions={{
          pageCount: Math.ceil((data?.count ?? 0) / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        selectAllText="Select Opportunities"
        footerControls={
          <OpportunitiesTableFooterControls
            companyTypeSlug={'education-cu'}
            onDownloadButtonClick={() => setShowExportAllModal(true)}
          />
        }
      />

      {openDealModal && (
        <CreateDealModalForTable
          optimisticUiTarget="companies"
          companyIds={dealCompanyIds}
          show={openDealModal}
          handleClose={() => setOpenDealModal(false)}
          onDealCreated={refetch}
          queryKey={TABLE_QUERY_KEY}
          filterAndSortParams={filterAndSortParams}
          idAccessor="id"
          data={data?.results ?? []}
          originCampaignId={props.baseFilters?.campaign}
          companyTypeSlug="education-cu"
        />
      )}
    </TableContainer>
  )
}

UniversitiesTableComponent.displayName = 'UniversitiesTable'

export function UniversitiesTable(
  props: UniversitiesTableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={[
        'Opportunity Attributes',
        'Campaigns and Deals',
        'Classification and Enrollment',
        'Dining Services',
      ]}
    >
      <DataTableProvider tableKey={tableKey}>
        <UniversitiesTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`
