import { CampaignsTable } from 'features/campaigns/CampaignsTable'
import {
  PageHeader,
  PageTitle,
  PageTitleRow,
} from 'components/FbUI/Page/styles'
import { CreateEditCampaignModal } from 'features/campaigns/CreateEditCampaignModal'
import FbButton from 'components/FbUI/FbButton'
import { useState } from 'react'
import { Campaign } from 'models/campaign'
import { Page } from 'components/FbUI/Page'
import { DataTableProvider } from 'components/DataTable'
import { featureFlagService } from 'utils/featureFlagService'
import { useNavigate } from 'react-router-dom'

export function CampaignsPage() {
  const featureFlag = featureFlagService()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>()

  function handleRowEdit(campaign: Campaign) {
    setShowModal(true)
    setSelectedCampaign(campaign)
  }

  function openModal() {
    setShowModal(true)
  }

  function hideModal() {
    setShowModal(false)
    setSelectedCampaign(undefined)
  }

  if (!featureFlag.enableCampaigns) {
    navigate('/')
    return null
  }

  return (
    <DataTableProvider tableKey="campaign-overview-table">
      <Page>
        <PageHeader>
          <PageTitleRow>
            <PageTitle>Campaigns Overview</PageTitle>
          </PageTitleRow>
          <FbButton style={{ width: 200 }} onClick={() => openModal()}>
            Create Campaign
          </FbButton>
        </PageHeader>
        <CampaignsTable
          onEditCampaign={handleRowEdit}
          containerStyle={{ height: 'calc(100vh - 236px)' }}
          onOpenCampaign={(camp) => {
            window.open(`/campaigns/${camp.id}/`, '_blank')
          }}
        />
        <CreateEditCampaignModal
          isOpen={showModal}
          onClose={hideModal}
          editingCampaign={selectedCampaign}
        />
      </Page>
    </DataTableProvider>
  )
}
