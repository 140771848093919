import * as S from './styles'

export function Page({
  children,
  noContainer,
  noMaxHeight,
  className,
}: {
  children: React.ReactNode
  noContainer?: boolean
  noMaxHeight?: boolean
  className?: string
}) {
  return (
    <S.PageDiv className={className} noMaxHeight={noMaxHeight}>
      {!noContainer ? <S.Container>{children}</S.Container> : children}
    </S.PageDiv>
  )
}

export function TableContainer({
  children,
  noContainer,
  noMaxHeight,
  className,
}: {
  children: React.ReactNode
  noContainer?: boolean
  noMaxHeight?: boolean
  className?: string
}) {
  return (
    <S.PageDiv className={className} noMaxHeight={noMaxHeight}>
      {!noContainer ? <S.Container>{children}</S.Container> : children}
    </S.PageDiv>
  )
}
