export const InstagramTextPercentileOptions = [
  { value: 'Very High', label: 'Very High' },
  { value: 'High', label: 'High' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Low', label: 'Low' },
  { value: 'Very Low', label: 'Very Low' },
]

export type InstagramTextPercentileGroup =
  (typeof InstagramTextPercentileOptions)[number]['value']
