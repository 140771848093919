import { Modal } from 'components/UI/Modal/Modal'
import { ColumnTable } from './ColumnTable'
import { OrderableListNew } from 'components/OrderableListNew/OrderableListNew'
import { ColumnDragAndDropItem } from './ColumnDragAndDropItem'
import { useEffect, useState } from 'react'
import { useColumnStoreContext } from 'stores/ColumnsStore/useColumnsStoreContext'
import {
  Column,
  getAllColumns,
  getDefaultSelectedColumns,
  getHiddenColumns,
  getReadOnlyColumns,
} from 'stores/ColumnsStore/ColumnsStore'
import { MdOutlineClearAll } from 'react-icons/md'
import TableButton from 'components/Buttons/TableButtons/TableButton'
import { HiOutlineViewColumns } from 'react-icons/hi2'
import _ from 'lodash'

export const ColumnModal = () => {
  const [open, setOpen] = useState(false)
  const defaultSelectedColumns = getDefaultSelectedColumns()
  const [selectedColumns, setSelectedColumns] = useState<Column[]>([])
  const [orderedColumns, setOrderedColumns] = useState<Column[]>([])
  const setSelectedColumnsStore = useColumnStoreContext(
    (state) => state.setSelectedColumns
  )
  const allColumns = getAllColumns()
  const readOnlyColumns = getReadOnlyColumns()
  const hiddenColumns = getHiddenColumns()

  useEffect(() => {
    if (defaultSelectedColumns) {
      const filteredColumns = defaultSelectedColumns.filter(
        (column) => column.isEditable
      )
      setOrderedColumns(filteredColumns)
      setSelectedColumns(filteredColumns)
    }
  }, [defaultSelectedColumns])

  useEffect(() => {
    // We want to keep the order and just add/remove columns
    setOrderedColumns((columns) => {
      const newColumns = selectedColumns.filter(
        (column) => !columns.some((c) => c.id === column.id)
      )
      const existingFilteredColumns = columns.filter((column) =>
        selectedColumns.some((c) => c.id === column.id)
      )
      return [...existingFilteredColumns, ...newColumns]
    })
  }, [selectedColumns])

  const updateOrderedColumns = (columnsIds: string[]) => {
    setOrderedColumns((columns) => {
      const newColumns = columnsIds
        .map((id) => columns.find((c) => c.id === id))
        .filter((c) => c) as Column[]
      return newColumns
    })
  }

  const handleApply = () => {
    // Keep the order from readOnlyColumns and hiddenColumns, just adding the orderedColumns
    const selectedColumns = []
    for (const originalColumn of allColumns) {
      if (readOnlyColumns.some((column) => column.id === originalColumn.id)) {
        selectedColumns.push(originalColumn)
      } else if (
        hiddenColumns.some((column) => column.id === originalColumn.id)
      ) {
        selectedColumns.push(originalColumn)
      } else if (
        orderedColumns.some((column) => column.id === originalColumn.id)
      ) {
        selectedColumns.push(originalColumn)
      }
    }

    const selectedOrderedColumns: Column[] = []
    let sortedIndex = 0

    // Iterate through selectedColumns and insert elements from orderedColumns in the correct order
    for (const col of selectedColumns) {
      if (orderedColumns.some((orderedCol) => _.isEqual(orderedCol, col))) {
        // Find the correct element from the sorted order based on the orderedColumns
        selectedOrderedColumns.push(orderedColumns[sortedIndex])
        sortedIndex++
      } else {
        // Keep the columns that aren't part of orderedColumns in their original place
        selectedOrderedColumns.push(col)
      }
    }

    setSelectedColumnsStore([...selectedOrderedColumns])
    setOpen(false)
  }

  const clearAll = () => {
    setSelectedColumns([])
  }

  return (
    <Modal
      open={open}
      onOpenChange={(open) => !open && setOpen(false)}
      title={'Table Columns'}
      description={
        'Choose a data table view from the Column Preset menu. You can can customize the view further by manually selecting and ordering columns.'
      }
      onAccept={handleApply}
      acceptButtonText={'Apply'}
      cancelButtonText="Cancel"
      size="lg"
      trigger={
        <TableButton
          text="Columns"
          icon={HiOutlineViewColumns}
          onClick={() => setOpen(true)}
        />
      }
      noPadding
      noScroll
    >
      <hr className="mt-[3px]" />
      {/* 2 columns with 1 divider */}
      <div className="grid grid-cols-2 divide-x divide-gray-300 h-[60vh]">
        <div className="p-[28px] h-full flex flex-col">
          <p className="mb-2">
            <span className="bg-gray-300 px-2 py-1 rounded-md mr-2">1</span>
            <strong>All Columns</strong>
          </p>
          <p>Select columns to display</p>
          <ColumnTable
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </div>
        <div className="p-[28px] h-full flex flex-col">
          <p className="mb-2">
            <span className="bg-gray-300 px-2 py-1 rounded-md mr-2">2</span>
            <strong>Selected Columns</strong>
          </p>
          <p>Adjust the display order of selected columns</p>
          {readOnlyColumns.map((column) => (
            <ColumnDragAndDropItem
              key={column.id}
              label={column.title}
              readOnly
            />
          ))}
          <OrderableListNew
            onOrderChangeKey={updateOrderedColumns}
            className="flex-1 overflow-y-auto pl-[14px] ml-[-14px]"
          >
            {orderedColumns.map((column) => (
              <ColumnDragAndDropItem
                key={column.id}
                label={column.title}
                onRemove={() =>
                  setSelectedColumns((columns) =>
                    columns.filter((c) => c.id !== column.id)
                  )
                }
              />
            ))}
          </OrderableListNew>
          {orderedColumns.length > 0 && (
            <div className="flex items-center mt-2 justify-end">
              <div
                onClick={clearAll}
                className="cursor-pointer flex items-center text-[#005A31]"
              >
                Clear All{' '}
                <MdOutlineClearAll className="ml-1" size={15} color="#005A31" />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export function getColumnSelectorRecipientId(tableKey: string) {
  return `${tableKey}-columns-modal`
}

export function ColumnSelectorRecipient(props: { tableKey: string }) {
  return <div id={getColumnSelectorRecipientId(props.tableKey)} />
}
