import styled from 'styled-components/macro'

export const PageDiv = styled.div<{ noMaxHeight?: boolean }>`
  padding: 20px 80px;
  background-color: #f5f5f5;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({ noMaxHeight }) =>
    !noMaxHeight && 'max(1000px, calc(100vh - 70px - 70px));'}

  @media (max-width: 1024px) {
    padding: 0;
  }
`

export const PageHeader = styled.div`
  padding: 16px 25px 0 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`

export const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  box-shadow:
    0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  overflow: hidden;
`

export const PageTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PageTitle = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #101828;
`
