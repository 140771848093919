import { useQuery } from '@tanstack/react-query'
import { createColumnHelper } from '@tanstack/react-table'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import { IColumnSort, IColumnVisibility } from 'components/DataTable/types'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import {
  CreateContactRequestJobModal,
  MAX_OPPORTUNITIES_REQUESTED,
} from 'components/Modals/CreateContactRequestJobModal'
import OpportunitiesTableFooterControls from 'components/Opportunities/OpportunitiesTableFooterControls'
import { Anchor } from 'components/UI/Anchor'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import { ContactResponse } from 'models/contacts'
import { K12ChainProxy } from 'models/k12'
import { useEffect, useMemo, useState } from 'react'
import {
  BsBriefcase,
  BsDownload,
  BsPhone,
  BsTags,
  BsTruck,
  BsXCircle,
} from 'react-icons/bs'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import styled from 'styled-components'
import { featureFlagService } from 'utils/featureFlagService'
import { formatInteger } from 'utils/formatting'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { AssignToCampaignModal } from '../../../features/campaigns/AssignToCampaignModal'
import { useCampaignUnassigner } from '../../../features/campaigns/useCampaignUnassigner'
import { FilterIdentifier } from '../../../models/saved_view'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import TableButtonAction from '../../Buttons/TableButtons/TableButtonAction'
import { CreateDealModalForTable } from '../../Deals/CreateDealModal/CreateDealModal'
import { FbLink } from '../../FbUI/FbLink'
import {
  AccountOwnerCell,
  CampaignsCell,
  DistributorsCell,
  SaleStagesCellFromDeals,
  TaglistCell,
} from '../../FbUI/StagePill'
import { TableSearch } from '../../Filters/TableSearch'
import DistributorAssignModal from '../../Modals/AssignmentModal/DistributorAssignModal'
import TagAssignModal from '../../Modals/AssignmentModal/TagAssignModal'
import { K12DistrictExportModal } from '../../Modals/ExportModal/K12DistrictExportModal'
import { IDropdownItem } from '../../UI/Dropdown/Dropdown'
import * as S from '../CommonTable.styles'
import { K12Filterset } from './K12Filterset'

import { cn } from '../../UI/cn'

type SortableFields = (keyof ContactResponse | string)[]

const sortableFields: SortableFields = [
  'deal_count',
  'contact_count',
  'note_count',
  'sales_stages',
  'k12district__lea_name',
  'k12district__lcity',
  'k12district__lstate',
  'k12district__zipcode',
  'k12district__locale',
  'k12district__gslo',
  'k12district__gshi',
  'k12district__student_count',
  'k12district__teachers_count',
  'k12district__operational_schools',
  'k12district__revenue',
  'k12district__food_service_expenditures',
  'k12district__free_lunch',
  'k12district__reduced_lunch',
  'k12district__total_supplemented_lunch',
  'k12district__uncategorized_lunch',
  'k12district__free_lunch_perc',
  'k12district__reduced_lunch_perc',
  'k12district__total_supplemented_lunch_perc',
  'k12district__uncategorized_lunch_perc',
  'k12district__estimated_meals_per_day',
  'k12district__county',
]

const PAGE_SIZE = 100

interface K12TableProps {
  setTotalRowsCount?: (count: number) => void
  pageParamName?: string
  baseFilters?: Record<string, any>
  tableKey: string
  filterIdentifierModifier?: FilterIdentifier
}

function K12TableComponent(props: K12TableProps) {
  const api = apiService()
  const featureFlag = featureFlagService()
  const {
    state: { sorting, rowSelection, isAllRowsSelected, totalSelectedRows },
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = useDataTableContext()

  const [pagination, setPagination] = usePaginationURLParams(
    PAGE_SIZE,
    props.pageParamName
  )

  const hasCampaign = !!props.baseFilters?.campaign
  const IDENTIFIER = 'K12_TABLE' + (props.filterIdentifierModifier ?? '')
  const k12FilterStore = filterStoreRepo.getStore(
    IDENTIFIER as FilterIdentifier
  )

  const filterParams = {
    ...useFilterParams(k12FilterStore),
    ...props.baseFilters,
  }

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...filterParams,
      ...sortParams,
    }),
    [filterParams, sortParams]
  )

  const totalRowsSelected = useMemo(() => {
    return isAllRowsSelected
      ? totalSelectedRows
      : Object.keys(rowSelection).length
  }, [isAllRowsSelected, rowSelection])

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
  })

  // DATA FETCHING
  const { isFetching, data, refetch } = useQuery({
    staleTime: Infinity,
    queryKey: TABLE_QUERY_KEY,
    queryFn: async () => {
      clearSelectedRows()

      const res = await api.getK12DistrictsList({
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        ...filterAndSortParams,
      })
      props.setTotalRowsCount?.(res.count)
      setTotalRowsInBackend(res.count)
      return res
    },
  })
  // END DATA FETCHING

  const unassignFromCampaign = useCampaignUnassigner('k12', {
    data: data?.results ?? [],
    accessorKey: 'id',
    tableQueryKey: TABLE_QUERY_KEY,
    requestParams: filterAndSortParams,
    campaignId: props?.baseFilters?.campaign,
  })

  useEffect(() => {
    clearSelectedRows()
  }, [data])

  const selectedIds = useMemo(() => {
    const ids: number[] = []
    const selection = Object.keys(rowSelection)
    for (const s of selection) {
      const id = data?.results[parseInt(s)]?.id
      if (id) {
        ids.push(id)
      }
    }
    return ids
  }, [rowSelection])

  const [showAssignTags, setShowAssignTags] = useState(false)
  const [showAssignDistributors, setShowAssignDistributors] = useState(false)
  const [openDealModal, setOpenDealModal] = useState(false)
  const [dealCompanuIds, setDealCompanyIds] = useState<number[]>([])
  const [showContactRequest, setShowContactRequest] = useState(false)
  const [showExportAllModal, setShowExportAllModal] = useState(false)

  const handleCreateDeals = () => {
    if (!data) return
    const companyIds = Object.keys(rowSelection).map(
      (idx) => data.results[parseInt(idx)].id
    )
    setDealCompanyIds(companyIds)
    setOpenDealModal(true)
  }

  const columns = useK12Columns({ sorting })

  useEffect(() => {
    const totalCount = data?.count ?? 0
    props.setTotalRowsCount?.(totalCount)
    setTotalRowsInBackend?.(totalCount)
  }, [data?.count])

  const actions = useMemo(() => {
    const actions: IDropdownItem[] = [
      {
        label: 'Create Deals',
        icon: <BsBriefcase size={20} />,
        callback: handleCreateDeals,
      },
      {
        label: 'Manage Tags',
        icon: <BsTags size={20} />,
        callback: () => setShowAssignTags(true),
      },
      {
        label: 'Add Known Distributors',
        icon: <BsTruck size={20} />,
        callback: () => setShowAssignDistributors(true),
      },
      {
        label: 'Export Selected',
        icon: <BsDownload size={20} />,
        callback: () => setShowExportAllModal(true),
      },
    ]

    if (featureFlag.enableContactRequests) {
      actions.push({
        label: 'Request Contacts',
        icon: <BsPhone size={20} />,
        callback: () => setShowContactRequest(true),
        disabled: totalRowsSelected > MAX_OPPORTUNITIES_REQUESTED,
        disabledTooltipText: `To request contacts, select less than ${MAX_OPPORTUNITIES_REQUESTED} opportunities.`,
      })
    }

    if (featureFlag.enableCampaigns) {
      if (!hasCampaign) {
        actions.push({
          label: 'Push to Campaign',
          icon: <BsDownload size={20} />,
          subContent: (
            <AssignToCampaignModal
              data={data?.results ?? []}
              accessorKey={'id'}
              tableQueryKey={TABLE_QUERY_KEY}
              requestParams={filterAndSortParams}
              companyTypeSlug={'education-k-12'}
              buttonLess
            />
          ),
        })
      } else {
        actions.push({
          label: 'Unassign from Campaign',
          icon: <BsXCircle size={20} />,
          callback: () => unassignFromCampaign(),
        })
      }
    }

    return actions
  }, [data, hasCampaign, featureFlag])

  return (
    <TableContainer>
      <S.SearchContainer>
        <div className="flex w-full gap-2 items-center">
          <TableSearch
            filterStore={k12FilterStore}
            searchPlaceholder="Search schools by name"
            className="flex-1"
          />

          <K12Filterset filterIdentifier={IDENTIFIER as FilterIdentifier} />

          <ColumnSelectorRecipient tableKey={props.tableKey} />
        </div>

        <div className={cn('flex flex-row justify-between gap-4')}>
          <TableButtonAction
            items={actions}
            disabled={!totalSelectedRows}
            selectedRowsCount={totalSelectedRows ?? 0}
          />
          <FilterChips
            identifier={IDENTIFIER as FilterIdentifier}
            clearAllButton
            showActive
            store={k12FilterStore}
          />
        </div>
      </S.SearchContainer>

      <CreateContactRequestJobModal
        data={data?.results ?? []}
        open={showContactRequest}
        onClose={() => setShowContactRequest(false)}
        accessorKeys={['id']}
        tableQueryKey={TABLE_QUERY_KEY}
        requestParams={filterAndSortParams}
        companyTypeSlug={'education-k-12'}
      />

      <TagAssignModal
        show={showAssignTags}
        handleClose={() => setShowAssignTags(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        filterAndSortParams={filterAndSortParams}
        apiTagsAction={api.k12BulkEditTags}
        idAccessor="id"
      />

      <DistributorAssignModal
        show={showAssignDistributors}
        handleClose={() => setShowAssignDistributors(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="id"
        filterAndSortParams={filterAndSortParams}
        apiDistributorsAction={api.k12BulkEditDistributors}
      />

      <K12DistrictExportModal
        show={showExportAllModal}
        _handleClose={() => {
          setShowExportAllModal(false)
        }}
        filters={{
          ...filterAndSortParams,
          id: selectedIds,
        }}
        count={selectedIds.length || data?.count || 0}
      />

      <DataTable
        tableKey={props.tableKey}
        loading={isFetching}
        autoLayout
        data={data?.results ?? []}
        columns={columns}
        sortableFields={sortableFields}
        virtualizeRows={true}
        enableRowSelection
        isPaginationEnabled={true}
        defaultSort={[
          {
            id: 'k12district__estimated_meals_per_day',
            desc: true,
          } as IColumnSort<object>,
        ]}
        paginationOptions={{
          pageCount: Math.ceil((data?.count ?? 0) / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        selectAllText="Select Opportunities"
        footerControls={
          <OpportunitiesTableFooterControls
            companyTypeSlug={'education-k-12'}
            onDownloadButtonClick={() => setShowExportAllModal(true)}
          />
        }
      />

      <CreateDealModalForTable
        optimisticUiTarget="k12"
        companyIds={dealCompanuIds}
        show={openDealModal}
        handleClose={() => setOpenDealModal(false)}
        onDealCreated={refetch}
        queryKey={TABLE_QUERY_KEY}
        filterAndSortParams={filterAndSortParams}
        data={data?.results ?? []}
        idAccessor="id"
        originCampaignId={props.baseFilters?.campaign}
        companyTypeSlug="education-k-12"
      />
    </TableContainer>
  )
}

K12TableComponent.displayName = 'K12Table'

export function K12Table(
  props: K12TableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props
  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={[
        'Opportunity Attributes',
        'School Insights',
        'Campaigns and Deals',
        'Dining Services',
      ]}
    >
      <DataTableProvider tableKey={tableKey}>
        <K12TableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

const integerFormatterOrFallback = (
  value: number | null | undefined,
  fallback: string = '-'
) => (value ? formatInteger(value) : fallback)
const percentFormatterOrFallback = (
  value: number | null | undefined,
  fallback: string = '-'
) => {
  if (value) {
    return formatInteger(value) + '%'
  } else {
    return fallback
  }
}

export const useK12Columns = ({
  sorting,
}: {
  sorting?: IColumnSort<object>[]
}) => {
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const columnHelper = createColumnHelper<K12ChainProxy>()

  const columns = useMemo(() => {
    let cols = [
      columnHelper.accessor('k12district.lea_name', {
        id: 'k12district__lea_name',
        header: () => null,
        meta: {
          isEditable: false,
          isFirstColumn: true,
          headerTitle: 'School District',
          headerGroup: 'Opportunity Attributes',
        },
        size: 250,
        cell: (info) => {
          if (info.row.original?.k12district) {
            const name = info.getValue()
            return (
              <FbLink
                to={`/k12/${preferences?.company_type_prefix_map[ContactCompanyType.K12District]}${info.row.original?.k12district?.id}`}
                target="_blank"
              >
                {name}
              </FbLink>
            )
          } else {
            return (
              <FbLink
                to={`/company/${preferences?.company_type_prefix_map[ContactCompanyType.UserGenerated]}${info.row.original?.id}`}
                target="_blank"
              >
                {info.row.original.name}
              </FbLink>
            )
          }
        },
      }),
      columnHelper.accessor('k12district.address', {
        id: 'k12district__address',
        meta: {
          tooltip: tooltips?.['opps_full_address'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Address',
        size: 200,
      }),
      columnHelper.accessor('k12district.zipcode', {
        id: 'k12district__zipcode',
        meta: {
          tooltip: tooltips?.['opps_zipcode'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Zipcode',
        size: 100,
      }),
      columnHelper.accessor('k12district.lcity', {
        id: 'k12district__lcity',
        meta: {
          tooltip: tooltips?.['opps_city'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'City',
        size: 150,
      }),
      columnHelper.accessor('k12district.lstate', {
        id: 'k12district__lstate',
        meta: {
          tooltip: tooltips?.['opps_full_address'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'State',
        size: 100,
      }),
      columnHelper.accessor('k12district.county', {
        id: 'k12district__county',
        meta: {
          tooltip: tooltips?.['opps_county'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'County',
        size: 200,
      }),
      columnHelper.accessor('k12district.locale', {
        id: 'k12district__locale',
        meta: {
          tooltip: tooltips?.['opps_locale'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Locale',
        size: 200,
      }),
      columnHelper.accessor('k12district.operational_schools', {
        id: 'k12district__operational_schools',
        meta: {
          tooltip: tooltips?.['opps_schools_count'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'School Count',
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.gslo', {
        id: 'k12district__gslo',
        meta: {
          tooltip: tooltips?.['opps_lowest_grade'],
          headerGroup: 'School Insights',
        },
        header: 'Lowest Grade',
        size: 100,
      }),
      columnHelper.accessor('k12district.gshi', {
        id: 'k12district__gshi',
        meta: {
          tooltip: tooltips?.['opps_highest_grade'],
          headerGroup: 'School Insights',
        },
        header: 'Highest Grade',
        size: 100,
      }),
      columnHelper.accessor('k12district.student_count', {
        id: 'k12district__student_count',
        meta: {
          tooltip: tooltips?.['opps_students'],
          headerGroup: 'School Insights',
        },
        header: 'Students',
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),

      columnHelper.accessor('k12district.teachers_count', {
        id: 'k12district__teachers_count',
        meta: {
          tooltip: tooltips?.['opps_teachers'],
          headerGroup: 'School Insights',
        },
        header: 'Teachers',
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),

      columnHelper.accessor('k12district.phone', {
        id: 'k12district__phone',
        meta: {
          tooltip: tooltips?.['opps_phone'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Phone',
        size: 150,
      }),

      columnHelper.accessor('campaigns', {
        header: 'Campaigns',
        meta: {
          headerGroup: 'Campaigns and Deals',
        },
        cell: (info) => {
          return <CampaignsCell campaigns={info.getValue()} />
        },
        size: 260,
      }),

      columnHelper.display({
        id: 'sales_stages',
        meta: {
          tooltip: tooltips?.['opps_sales_stages'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Sales Stages',
        size: 260,
        cell: (info) => {
          return (
            <SaleStagesCellFromDeals
              deals={info.row.original?.deals}
              sortAsc={
                sorting?.length && sorting[0].id === 'sales_stages'
                  ? !sorting[0].desc
                  : undefined
              }
            />
          )
        },
      }),
      columnHelper.display({
        id: 'deals__account_owner',
        meta: {
          tooltip: tooltips?.['opps_account_owners'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Account Owners',
        size: 260,
        cell: (info) => {
          const deals = info.row.original?.deals
          return <AccountOwnerCell deals={deals} />
        },
      }),

      columnHelper.accessor('taglist', {
        id: 'taglist',
        meta: {
          tooltip: tooltips?.['opps_tags'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Tags',
        size: 260,
        cell: (info) => {
          const taglist = info.getValue()
          return <TaglistCell taglist={taglist} />
        },
      }),
      columnHelper.accessor('contact_count', {
        id: 'contact_count',
        meta: {
          tooltip: tooltips?.['opps_contact_count'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Contacts',
        size: 110,
        cell: (info) => {
          return info.getValue() || '-'
        },
      }),
      columnHelper.accessor('note_count', {
        id: 'note_count',
        meta: {
          tooltip: tooltips?.['opps_note_count'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Notes',
        size: 110,
        cell: (info) => {
          return info.getValue() || '-'
        },
      }),
      columnHelper.accessor('distributors', {
        id: 'distributors',
        meta: {
          tooltip: tooltips?.['opps_distributors'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Known Distributors',
        size: 260,
        cell: (info) => {
          return (
            <DistributorsCell distributors={info.row.original?.distributors} />
          )
        },
      }),
      columnHelper.accessor('k12district.revenue', {
        id: 'k12district__revenue',
        meta: {
          tooltip: tooltips?.['opps_k12_revenue'],
          headerGroup: 'School Insights',
        },
        header: 'Revenue',
        size: 200,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.reduced_lunch', {
        id: 'k12district__reduced_lunch',
        meta: {
          tooltip: tooltips?.['opps_reduced_lunch_count'],
          headerGroup: 'Dining Services',
        },
        header: 'Reduced Lunch',
        size: 250,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.free_lunch', {
        id: 'k12district__free_lunch',
        meta: {
          tooltip: tooltips?.['opps_free_lunch_count'],
          headerGroup: 'Dining Services',
        },
        header: 'Free Lunch',
        size: 250,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.estimated_meals_per_day', {
        id: 'k12district__estimated_meals_per_day',
        meta: {
          tooltip: tooltips?.['opps_k12_meals_day'],
          headerGroup: 'Dining Services',
        },
        header: 'Estimated Meals Per Day',
        size: 200,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.food_service_expenditures', {
        id: 'k12district__food_service_expenditures',
        meta: {
          tooltip: tooltips?.['opps_k12_fs_expenditures'],
          headerGroup: 'Dining Services',
        },
        header: 'Food Service Expenditures',
        size: 200,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.total_supplemented_lunch', {
        id: 'k12district__total_supplemented_lunch',
        meta: {
          tooltip: tooltips?.['opps_supplemented_lunch_count'],
          headerGroup: 'Dining Services',
        },
        header: 'Total Supplemented Lunch',
        size: 250,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.total_supplemented_lunch_perc', {
        id: 'k12district__total_supplemented_lunch_perc',
        meta: {
          tooltip: tooltips?.['opps_supplemented_lunch_percent'],
          headerGroup: 'Dining Services',
        },
        header: 'Total Supplemented Lunch (%)',
        size: 250,
        cell: (info) => {
          return percentFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('deal_count', {
        id: 'deal_count',
        meta: {
          tooltip: tooltips?.['opps_deals_count'],
          rightAlign: true,
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Deals',
        size: 110,
        cell: (info) => {
          return info.row.original.deals?.length || '-'
        },
      }),
      columnHelper.accessor('k12district.domain', {
        id: 'k12district__domain',
        meta: {
          tooltip: tooltips?.['opps_domain'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Domain',
        size: 200,
        cell: (info) => {
          const domain = info.getValue()
          if (!domain) {
            return '-'
          }
          return (
            <Anchor href={'//' + domain} target="_blank">
              {domain}
            </Anchor>
          )
        },
      }),
      columnHelper.accessor('k12district.google_place_url', {
        id: 'k12district__google_place_url',
        meta: {
          tooltip: tooltips?.['opps_google_place_url'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Place URL',
        size: 200,
        cell: (info) => {
          const value = info.getValue()
          if (!value) {
            return '-'
          }
          return (
            <Anchor href={value} target="_blank">
              View
            </Anchor>
          )
        },
      }),
      columnHelper.accessor('k12district.uncategorized_lunch', {
        id: 'k12district__uncategorized_lunch',
        meta: {
          tooltip: tooltips?.['opps_uncat_lunch_count'],
          headerGroup: 'Dining Services',
        },
        header: 'Uncategorized Supplemented Lunch',
        size: 250,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.free_lunch_perc', {
        id: 'k12district__free_lunch_perc',
        meta: {
          tooltip: tooltips?.['opps_free_lunch_percent'],
          headerGroup: 'Dining Services',
        },
        header: 'Free Lunch (%)',
        size: 250,
        cell: (info) => {
          return percentFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.reduced_lunch_perc', {
        id: 'k12district__reduced_lunch_perc',
        meta: {
          tooltip: tooltips?.['opps_reduced_lunch_percent'],
          headerGroup: 'Dining Services',
        },
        header: 'Reduced Lunch (%)',
        size: 250,
        cell: (info) => {
          return percentFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.uncategorized_lunch_perc', {
        id: 'k12district__uncategorized_lunch_perc',
        meta: {
          tooltip: tooltips?.['opps_uncat_lunch_percent'],
          headerGroup: 'Dining Services',
        },
        header: 'Uncategorized Supplemented Lunch (%)',
        size: 250,
        cell: (info) => {
          return percentFormatterOrFallback(info.getValue()) || '-'
        },
      }),
    ]

    const featureFlag = featureFlagService()
    if (!featureFlag.enableCampaigns) {
      cols = cols.filter((col) => !col.id?.includes('campaigns'))
    }
    return cols
  }, [columnHelper])

  return columns
}

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`
